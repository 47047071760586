import React from "react";
import { motion } from "framer-motion";
import {
  slideUpVariants,
  slideLefVariants,
} from "../CustomHooks/animations/animation";

const About = () => {
  return (
    <div
      id="about"
      className="lg:w-[80%] w-[90%] m-auto py-[60px] flex lg:flex-row  flex-col  justify-between items-start gap-[50px]"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="lg:w-[60]% w-full flex flex-col justify-center items-start "
      >
        <motion.h1
          variants={slideLefVariants}
          className="text-black-500 text-xl font-bold"
        >
          About US
        </motion.h1>
        <motion.h1
          variants={slideLefVariants}
          className="text-black uppercase text-[40px] font-bold "
        >
          SHRI<span className="text-orange-500 italic">ARC </span>
        </motion.h1>
        <div className="w-[120px] h-[6px] bg-orange-500"></div>
        <p className="italic  text-3xl text-black mt-[60px]  ">
          We are the leader with 5 years of experience in the construction
          market!
        </p>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="lg:w-[4 0]% w-full flex flex-col justify-center items-start gap-6"
      >
        <p className="text-black text-lg text-justify ">
          Since our inception in 2014, we are into manufacturing of bulk drug
          Intermediates and served our customers with great quality products
          along with Active Pharmaceutical Ingredients (APIs) exporting into 10+
          countries around the globe. Our core competence is to cater to the
          ever-changing and evolving requirements of our quality-conscious
          clients who are looking for manufacturers committed to quality along
          with timely delivery and competitive prices. We have recently
          developed many basic and advanced intermediates and APIs at our
          in-house R & D center to cater to existing and new customers.
          (injected humour and the like).
        </p>
      </motion.div>
    </div>
  );
};

export default About;
