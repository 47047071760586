import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  slideUpVariants,
  slideLefVariants,
  slideRightVariants,
} from "../CustomHooks/animations/animation";
import { useNavigate } from "react-router-dom";

const images = [
  {
    src: "https://c4.wallpaperflare.com/wallpaper/303/255/774/equipment-medicine-laboratory-professionals-wallpaper-preview.jpg",
    text: (
      <motion.h1
        variants={slideRightVariants}
        className="text-white text-[30px] uppercase font-bold"
      >
        Evaluation of the Current Safety"
      </motion.h1>
    ),
  },
  {
    src: "https://www.scilifelab.se/wp-content/uploads/2023/05/PM_Capability_BG6_approved-April-2023.png",
    text: (
      <motion.h1
        variants={slideRightVariants}
        className="text-white-500 text-[30px] uppercase font-bold"
      >
        Shariarc Pharmaceutical Story Priority Services Delivered with{" "}
      </motion.h1>
    ),
  },
  {
    src: "https://plus.unsplash.com/premium_photo-1672163163579-e5d4aedd26af?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWVkaWNpbmVzfGVufDB8fDB8fHww",
    text: (
      <motion.h1
        variants={slideRightVariants}
        className="text-white-500 text-[30px] uppercase font-bold"
      >
        Crafting a better future.
      </motion.h1>
    ),
  },
];

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); 

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <div
        id="home"
        className=" border-red bsolute w-full flex left-1 items-center lg:h-[700px]  h-fit bg-center bg-cover top-12px"
        style={{
          backgroundImage: `url(${images[activeIndex].src})`,
          transition: "background-image 1s ease-in-out",
          backgroundSize: "cover",
          height: "510px",
          backgroundPosition: "center",
          boxShadow: "0px 4px 1px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="absolute top-0 inset-0 bg-black bg-opacity-50"></div>

        {/* Text Section */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideUpVariants}
          className="relative z-10 text-center lg:w-[60%] w-full flex flex-col justify-center items-center gap-4 text-white px-[20px]"
        >
          <motion.h1
            variants={slideLefVariants}
            className="text-white-700 text-4xl font-semibold"
          >
            SHARIARC PHARMA
          </motion.h1>
          {images[activeIndex].text}
          <div className="w-[120px] h-[6px] bg-orange-500"></div>
          <p className="text-white text-[20px]">
            SHRI<span className="text-orange-500 italic">ARC </span>{" "}
            Pharmaceuticals aims to connect professionals with companies and
            organizations in the sector of laboratory automatio
          </p>
        </motion.div>
      </div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="g:w-[80%] w-[90%] m-auto py-[60px] flex flex-col justify-center items-center   gap-4 "
      >
        <motion.h1
          variants={slideRightVariants}
          className="text-xl text-black-500 font-medium "
        >
          Shariarc Pharmaceuticals Private Limited is a marketing based
          pharmaceutical company with its main focus on making available quality
          pharmaceutical products at affordable costs to all.
        </motion.h1>
        <div className="w-[120px] h-[6px] bg-orange-500"></div>

        <div
          className=" flex lg:flex-row  flex-col  justify-between gap-[20px]"
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={slideUpVariants}
            className="lg:w-[60]% w-full h-[500px] flex flex-col justify-center items-start "
          >
            <motion.img
              initial="hidden"
              whileInView="visible"
              variants={slideUpVariants}
              src="https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0"
              alt="About Us"
              className=" lg:w-[60]% w-full h-auto rounded-lg shadow-lg"
            />
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={slideUpVariants}
            className="lg:w-[4 0]% w-full flex flex-col justify-center items-start gap-6"
          >
            <motion.h1
              initial="hidden"
              whileInView="visible"
              variants={slideUpVariants}
              className="text-xl font-bold"
            >
              Welcome To{" "}
              <span className="text-3xl text-orange-500 font-semibold">
                ShariARC
              </span>{" "}
              Pharmaceuticals Private Limited. Leading Pharma Company in India.
            </motion.h1>
            <p className="text-black text-lg text-justify ">
              Since our inception in 2014, we are into manufacturing of bulk
              drug Intermediates and served our customers with great quality
              products along with Active Pharmaceutical Ingredients (APIs)
              exporting into 10+ countries around the globe. Our core competence
              is to cater to the ever-changing and evolving requirements of our
              quality-conscious clients who are looking for manufacturers
              committed to quality along with timely delivery and competitive
              prices. We have recently developed many basic and advanced
              intermediates and APIs at our in-house R & D center to cater to
              existing and new customers. (injected humour and the like).
            </p>
          </motion.div>
        </div>
      </motion.div>
      <div className="w-full p-[20px] flex justify-between items-center bg-orange-500">
      <h3 className="text-xl font-bold text-white">
        Have An Order? Get In Touch With - Galaxus Pharmaceuticals.
      </h3>
      <button
        className="bg-orange-600 hover:bg-orange-700 border-2 border-white px-10 py-3 rounded-lg text-white font-bold transition duration-300"
        onClick={() => navigate('/contactUs')}
      >
        CONTACT US
      </button>
    </div>
    </>
  );
};

export default Home;
