// import building from "../src/assets/building.svg";
// import construction from "../src/assets/construction.svg";
// import design from "../src/assets/design.svg";
// import document from "../src/assets/document.svg";
// import paint from "../src/assets/paint.svg";
// import support from "../src/assets/support.svg";

import { IoDocumentTextSharp } from "react-icons/io5";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import { FaSitemap } from "react-icons/fa";

// import client1 from "../src/assets/client1.png";
// import client2 from "../src/assets/client2.png";
// import client3 from "../src/assets/client3.png";

// import project1 from "../src/assets/project1.jpg";
// import project2 from "../src/assets/project2.jpg";
// import project3 from "../src/assets/project3.jpg";
// import project4 from "../src/assets/project4.jpg";
// import project5 from "../src/assets/project5.jpg";
// import project6 from "../src/assets/project6.jpg";
// import project7 from "../src/assets/project7.jpg";
// import project8 from "../src/assets/project8.jpg";

export const allservices = [
  {
    // icon: building,
    title: "BUILDING RENOVATION",
    about:
      "Sample text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    // icon: construction,
    title: "CONSTRUCTION SERVICES",
    about:
      "Sample text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    // icon: design,
    title: "DESIGN & PLANNING",
    about:
      "Sample text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    // icon: document,
    title: "DOCUMENTATION",
    about:
      "Sample text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    // icon: paint,
    title: "INTERIOR DESIGN",
    about:
      "Sample text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    // icon: support,
    title: "CUSTOMER SUPPORT",
    about:
      "Sample text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

export const projects = [
  {
     image: 'https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0',
    title: "BUILDING RENOVATION",
  },
  {
    image: 'https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0',
    title: "BUILDING RENOVATION",
  },
  {
    image: 'https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0',
    title: "BUILDING RENOVATION",
  },
  {
    image: 'https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0',
    title: "BUILDING RENOVATION",
  },
  {
    image: 'https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0',
    title: "BUILDING RENOVATION",
  },
  {
    image: 'https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0',
    title: "BUILDING RENOVATION",
  },
  {
    image: 'https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0',
    title: "BUILDING RENOVATION",
  },
  {
    image: 'https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0',
    title: "BUILDING RENOVATION",
  },
];

export const planning = [
  {
    icon: IoDocumentTextSharp,
    title: "planning",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas gravida cursus",
  },
  {
    icon: MdOutlineDesignServices,
    title: "design",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas gravida cursus",
  },
  {
    icon: FaRegBuilding,
    title: "building",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas gravida cursus",
  },
  {
    icon: FaSitemap,
    title: "finish",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas gravida cursus",
  },
];

export const clients = [
  {
    // image: client1,
    name: "Alex Parker",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas gravida cursus",
    post: "Constructor",
  },
  {
    // image: client2,
    name: "Drew James",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas gravida cursus",
    post: "Architect",
  },
  {
    // image: client3,
    name: "Sam Peterson",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas gravida cursus",
    post: "Builder",
  },
];
