import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import logo from "../../Components/asserts/logohead.svg";

const ResponsiveAppBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/aboutUs" },
    { label: "Products", path: "/products" },
    { label: "Contact Us", path: "/contactUs" },
    { label: "Group Of Companies", path: "/groupOfCompanies" },
  ];

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="w-full flex bg-white justify-between items-center lg:px-16 px-6 py-4 sticky top-0 z-50 shadow-md">
      {/* Logo Section */}
      <div className="flex items-center">
        
          <img
            src={logo}
            alt="logo"
            width="50"
            height="40"
            className="cursor-pointer"
          />
          <h1 className="text-black md:text-2xl text-2xl font-bold font-rubik mt-1.5">
            SHRI<span className="text-orange-500 italic">ARC</span>
          </h1>
        
      </div>

      {/* Desktop Navigation */}
      <ul className="hidden lg:flex justify-center items-center gap-6">
        {navItems.map(({ label, path }) => (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) =>
              `cursor-pointer px-3 py-2 uppercase rounded-full text-[15px] font-bold transition-colors duration-200 ${
                isActive ? "text-orange-500" : "hover:text-orange-500"
              }`
            }
          >
            {label}
          </NavLink>
        ))}
      </ul>

      {/* Mobile Hamburger Menu */}
      <div className="lg:hidden flex items-center" onClick={toggleMenu}>
        {isOpen ? (
          <IoClose className="text-orange-500 text-3xl cursor-pointer" />
        ) : (
          <FaBars className="text-orange-500 text-3xl cursor-pointer" />
        )}
      </div>

      {/* Mobile Navigation Menu */}
      <div
        className={`${
          isOpen ? "flex" : "hidden"
        } lg:hidden flex-col justify-center items-center w-full bg-yellow-500 absolute top-[72px] left-0 p-4 shadow-lg`}
      >
        <ul className="flex flex-col justify-center items-center gap-4 w-full">
          {navItems.map(({ label, path }) => (
            <NavLink
              key={path}
              to={path}
              onClick={toggleMenu}
              className={({ isActive }) =>
                `cursor-pointer px-3 py-2 uppercase rounded-full text-[15px] font-bold transition-all duration-200 ${
                  isActive ? "text-black bg-white" : "hover:bg-black hover:text-white"
                }`
              }
            >
              {label}
            </NavLink>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default ResponsiveAppBar;
